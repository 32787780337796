const Flickr = require('flickr-sdk');
const flickr = new Flickr(process.env.FLICKR_API_KEY);

export function getLicensesInfos() {
	return new Promise((resolve) => {
		const licenses = []
		flickr.photos.licenses.getInfo().then(result => {
			result.body.licenses.license.forEach(l => {
				licenses[l.id] = l
			})
			resolve(licenses);
		})
	})
}

export function search(species) {
	return new Promise((resolve) => {
		flickr.photos.search({
		  text: species,
			tags: 'oiseau,oiseaux,bird,birds',
			extras: 'license,owner_name,tags',
		})
		.then(function (res) {
			resolve(res.body.photos.photo.random())
		})
	})
}
