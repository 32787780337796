const alcidae = [
  'Macareux moine',
].toSorted((a, b) => a.localeCompare(b))

const paridae = [
  'Mésange bleue',
  'Mésange charbonnière',
].toSorted((a, b) => a.localeCompare(b))

const turdidae = [
  'Merle noir',
].toSorted((a, b) => a.localeCompare(b))

const muscicapidae = [
  'Rougegorge familier',
  'Rougequeue noir',
].toSorted((a, b) => a.localeCompare(b))

const fringillidae = [
  'Pinson des arbres',
  'Chardonneret élégant',
  'Bouvreuil pivoine',
  'Serin cini',
  'Verdier d\'Europe',
  'Tarin des aulnes',
].toSorted((a, b) => a.localeCompare(b))

const phalacrocoracidae = [
  'Grand cormoran',
  'Cormoran huppé',
].toSorted((a, b) => a.localeCompare(b))

const sulidae = [
  'Fou de Bassan',
].toSorted((a, b) => a.localeCompare(b))

const passeridae = [
  'Moineau domestique',
  'Moineau friquet',
].toSorted((a, b) => a.localeCompare(b))

const threskiornithidae = [
  'Spatule blanche',
].toSorted((a, b) => a.localeCompare(b))

const ciconiidae = [
  'Cigogne noire',
  'Cigogne blanche',
].toSorted((a, b) => a.localeCompare(b))

const phoenicopteridae = [
  'Flamant rose',
].toSorted((a, b) => a.localeCompare(b))

const podicipedidae = [
  'Grèbe huppé',
].toSorted((a, b) => a.localeCompare(b))

const rallidae = [
  'Foulque macroule',
  'Gallinule poule-d\'eau',
].toSorted((a, b) => a.localeCompare(b))

const cuculidae = [
  'Coucou gris',
].toSorted((a, b) => a.localeCompare(b))

const columbidae = [
  'Tourterelle turque',
  'Pigeon ramier',
  'Tourterelle des bois',
  'Pigeon biset',
  'Pigeon colombin',
].toSorted((a, b) => a.localeCompare(b))

const ardeidae = [
  'Aigrette garzette',
  'Héron cendré',
  'Grande aigrette',
].toSorted((a, b) => a.localeCompare(b))

const picidae = [
  'Pic épeiche',
  'Pic épeichette',
  'Pic vert',
  'Pic noir',
].toSorted((a, b) => a.localeCompare(b))

const corvidae = [
  'Grand corbeau',
  'Corbeau freux',
  'Corneille noire',
  'Pie bavarde',
  'Geai des chênes',
].toSorted((a, b) => a.localeCompare(b))

const anatidae = [
  'Canard colvert',
  'Cygne tuberculé',
  'Oie cendrée',
  'Oie à tête barrée',
  'Canard souchet',
  'Fuligule morillon',
  'Canard pilet',
  'Canard siffleur',
  'Canard chipeau',
  'Sarcelle d\'hiver',
  'Sarcelle d\'été',

].toSorted((a, b) => a.localeCompare(b))

export const families = {
  alcidae,
  paridae,
  turdidae,
  muscicapidae,
  fringillidae,
  phalacrocoracidae,
  sulidae,
  passeridae,
  threskiornithidae,
  ciconiidae,
  picidae,
  phoenicopteridae,
  podicipedidae,
  anatidae,
  corvidae,
  columbidae,
  ardeidae,
  cuculidae,
  rallidae,
}
