{
  "name": "flickr-sdk",
  "version": "6.2.1",
  "description": "Almost certainly the best Flickr API client in the world for node and the browser",
  "keywords": [
    "flickr",
    "api",
    "sdk",
    "photos",
    "search"
  ],
  "main": "index.js",
  "types": "flickr-sdk.d.ts",
  "files": [
    "index.js",
    "flickr-sdk.d.ts",
    "flickr-sdk.js",
    "lib",
    "plugins",
    "services",
    "CHANGELOG.md",
    "LICENSE",
    "README.md"
  ],
  "browser": {
    "./plugins/oauth.js": "./plugins/oauth-browser.js",
    "./services/oauth.js": "./services/oauth-browser.js"
  },
  "scripts": {
    "reflect": "node script/reflect",
    "build-rest": "node script/build-rest > services/rest.js",
    "build-tests": "node script/build-tests",
    "build-docs": "node script/build-docs > README.md",
    "build-types": "node script/build-types > flickr-sdk.d.ts && npm run tsc",
    "build-client": "browserify -s Flickr $npm_package_main > flickr-sdk.js",
    "build": "npm run build-rest && npm run build-docs && npm run build-types && npm run build-client",
    "tsc": "npx tsc",
    "lint": "eslint .",
    "test": "mocha",
    "coverage": "nyc mocha",
    "prepublish": "npm run build"
  },
  "repository": {
    "type": "git",
    "url": "git+ssh://git@github.com/flickr/flickr-sdk.git"
  },
  "author": [
    "Jeremy Ruppel",
    "Alex Seville",
    "Phil Dokas"
  ],
  "license": "MIT",
  "engines": {
    "node": ">= 4"
  },
  "publishConfig": {
    "registry": "https://registry.npmjs.org"
  },
  "devDependencies": {
    "browserify": "^17.0.0",
    "chalk": "^5.0.0",
    "dotprune": "^0.1.0",
    "ejs": "^3.1.6",
    "eslint": "^8.2.0",
    "eslint-config-flickr": "~1.3.1",
    "eslint-plugin-header": "^3.1.1",
    "indent-string": "^5.0.0",
    "jsdoc-to-markdown": "^7.1.1",
    "mocha": "^9.1.3",
    "nock": "^13.2.0",
    "nyc": "^15.1.0",
    "ora": "^1.3.0",
    "p-limit": "^1.1.0",
    "require-dir": "~0.3.1",
    "sinon": "^12.0.1",
    "stringify-object": "^4.0.0"
  },
  "dependencies": {
    "superagent": "^6.1.0",
    "xml2js": "^0.5.0"
  },
  "bugs": {
    "url": "https://github.com/flickr/flickr-sdk/issues"
  },
  "homepage": "https://github.com/flickr/flickr-sdk#readme"
}
