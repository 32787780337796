import { updateImageInfos, changeImage, flash, changeAnswerText } from './ui.js';
import { getLicensesInfos, search } from './flickr.js';
import { families } from './species.js';

const species = []
const fieldset = document.querySelector('fieldset')

const updateSpecies = () => {
  species.splice(0, species.length);
  [...fieldset.querySelectorAll('input:checked')]
    .map(it => families[it.name])
    .forEach(f => species.push(...f))

}

Object.keys(families).forEach(family => {
  species.push(...families[family])
  const div = document.createElement('div')
  const input = document.createElement('input')
  input.id = family
  input.name = family
  input.type = 'checkbox'
  input.checked = true
  input.onclick = updateSpecies
  const label = document.createElement('label')
  label.setAttribute('for', family)
  label.onclick = updateSpecies
  label.innerHTML = family.charAt(0).toUpperCase() + family.slice(1)
  div.appendChild(input)
  div.appendChild(label)
  fieldset.appendChild(div)
})
species.sort((a, b) => a.localeCompare(b))


void async function() {
  const licenses = await getLicensesInfos();
  let pick = undefined

  const screenWidth = window.screen.width * window.devicePixelRatio;
  const isMobile = screenWidth < 900;

  const speciesSelect = document.getElementById('species')

  speciesSelect.onchange = () => {
    const answer = speciesSelect.value
    if (answer === pick) {
      changeAnswerText('Bonne réponse')
      flash('good')
    } else {
      changeAnswerText(`Mauvaise réponse, il s'agissait de ${pick}`)
      flash('bad')
    }
    nextImage()
  }

  function nextImage() {
    speciesSelect.value = ''
    pick = species.random()
    search(pick)
      .then(function(img) {
        changeImage(`https://live.staticflickr.com/${img.server}/${img.id}_${img.secret}_${isMobile ? 'w' : 'b'}.jpg`);
        const license = licenses[img.license]
        updateImageInfos(img.ownername, `https://www.flickr.com/people/${img.owner}/`, license.name, license.url);
      })
  }

  nextImage();

  species.forEach(s => {
    const opt = document.createElement('option')
    opt.value = s
    opt.text = s
    speciesSelect.add(opt)
  })
}()
