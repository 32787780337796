const answerDiv = document.getElementById('answer')
const birdImg = document.querySelector('#bird_image img')
const caption = document.querySelector('figcaption')

export function changeAnswerText(txt) {
	answerDiv.innerText = txt;
}

export function flash(clazz) {
	document.body.classList.toggle(clazz);
	setTimeout(() => {
		document.body.classList.remove(clazz);
	}, 250);
}

export function changeImage(src) {
	var i = new Image()
	i.onload = function() {
		birdImg.src = this.src;
	}
	i.src = src;
}

export function updateImageInfos(authorName, authorUrl, licenseName, licenseUrl) {
	const hasLink = licenseUrl != null && licenseUrl != ""
	const licenseLink = document.createElement(hasLink ? 'a' : 'span')
	if(hasLink) {
		licenseLink.href = licenseUrl
	}
	licenseLink.innerText = licenseName

	const author = document.createElement('a')
	author.id = 'author'
	author.innerText = authorName
	author.href = authorUrl

	caption.innerHTML = ''
	caption.appendChild(licenseLink)
	caption.appendChild(document.createTextNode(' '))
	caption.appendChild(author)
}

